import React, { useEffect, useState } from 'react';
import './Sam.css';
import { marked } from 'marked';

function Sam() {
  const [emails, setEmails] = useState([]);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(0);

  useEffect(() => {
    fetch('/readyToSendEmails.json')
      .then((response) => response.json())
      .then((data) => setEmails(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleCopy = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const cleanNode = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        node.removeAttribute('style');
        node.removeAttribute('class');
        node.removeAttribute('id');
        const attributes = [...node.attributes];
        for (const attr of attributes) {
          if (attr.name.startsWith('data-')) {
            node.removeAttribute(attr.name);
          }
        }
        for (const child of node.childNodes) {
          cleanNode(child);
        }
      }
    };

    cleanNode(doc.body);
    const cleanHtmlContent = doc.body.innerHTML;

    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([cleanHtmlContent], { type: 'text/html' }),
            'text/plain': new Blob([doc.body.textContent], { type: 'text/plain' }),
          }),
        ]);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = cleanHtmlContent;
      tempElement.style.position = 'absolute';
      tempElement.style.left = '-9999px';
      document.body.appendChild(tempElement);

      const range = document.createRange();
      range.selectNodeContents(tempElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand('copy');
        alert('Copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy: ', err);
        alert('Failed to copy content');
      }

      selection.removeAllRanges();
      document.body.removeChild(tempElement);
    }
  };

  const parseMarkdownToHTML = (content) => {
    return marked(content);
  };

  const extractBrandName = (email) => {
    if (!email || !email.brand) return 'Unknown Brand';
    return email.brand;
  };

  return (
    <div className="sam-container">
      <div className="brand-sidebar">
        <ul>
          {emails.map((email, index) => (
            <li
              key={index}
              className={selectedEmailIndex === index ? 'active' : ''}
              onClick={() => setSelectedEmailIndex(index)}
            >
              {extractBrandName(email)}
            </li>
          ))}
        </ul>
      </div>

      {emails.length > 0 && (
        <div className="email-card">
          <div className="email-header">{extractBrandName(emails[selectedEmailIndex])}</div>

          <div className="email-section">
            <label>To</label>
            <div className="email-input-container">
              <div className="email-input">
                {emails[selectedEmailIndex].to || 'No recipient available'}
              </div>
              <button className="copy-btn" onClick={() => handleCopy(emails[selectedEmailIndex].to)}>
                Copy
              </button>
            </div>
          </div>

          <div className="email-section">
            <label>Subject</label>
            <div className="email-input-container">
              <div className="email-input">
                {emails[selectedEmailIndex].subject}
              </div>
              <button
                className="copy-btn"
                onClick={() => handleCopy(emails[selectedEmailIndex].subject)}
              >
                Copy
              </button>
            </div>
          </div>

          <div className="email-section">
            <label>Content</label>
            <div className="email-textarea-container">
              <div
                className="email-textarea"
                dangerouslySetInnerHTML={{
                  __html: parseMarkdownToHTML(emails[selectedEmailIndex].content),
                }}
              />
              <button
                className="copy-btn"
                onClick={() =>
                  handleCopy(parseMarkdownToHTML(emails[selectedEmailIndex].content))
                }
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sam;
