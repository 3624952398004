import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Pilot from './Pilot';
import Sam from './Sam';
import Home from './HomePage'; // Import the Home component
import Sam2 from './Sam2'; // Import the new Sam2 component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Homepage route */}
        <Route path="/pilot" element={<Pilot />} />
        <Route path="/sam-caitlin" element={<Sam />} /> {/* Existing Sam route */}
        <Route path="/sam-shonen" element={<Sam2 />} /> {/* New Sam2 route */}
      </Routes>
    </Router>
  );
}

export default App;
