import React, { useState } from 'react';
import './HomePage.css';

function HomePage() {
  const [formState, setFormState] = useState({
    name: '',
    agency: '',
    email: ''
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/api/beta-sign-up', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(errorData.error || 'Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        // Clear form and show success message
        setFormState({ name: '', agency: '', email: '' });
        setSuccessMessage('Your registration has been received. We’ll contact you soon.');

        // Make the success message disappear after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 4000);
      })
      .catch(error => {
        console.error('Error:', error);
        setSuccessMessage('There was an error submitting your request. Please try again.');
      });
  };

  return (
    <div className="homepage-container">
      <div className="content">
        <h1>Rubybeam</h1>
        <h2>Automate your sponsorship outreach</h2>
        <p>Rubybeam helps influencer agencies streamline sponsorship opportunities by providing ready-to-send emails tailored to each brand.</p>
        <p>Simplify your workflow and save hours searching for brands to collaborate with.</p>
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="input-row">
            <input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              placeholder="Name"
              required
              className="name-input"
            />
            <input
              type="text"
              name="agency"
              value={formState.agency}
              onChange={handleChange}
              placeholder="Agency"
              required
              className="agency-input"
            />
          </div>
          <input
            type="email"
            name="email"
            value={formState.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className="email-input"
          />
          <button type="submit">Join Beta</button>
        </form>

        {/* Display success message if it's set */}
        {successMessage && (
          <p className="success-message">
            {successMessage.split('\n').map((str, idx) => (
              <React.Fragment key={idx}>{str}<br /></React.Fragment>
            ))}
          </p>
        )}
      </div>
      <div className="illustration-container">
        <img src="/Illustration.jpg" alt="Rubybeam Illustration" className="illustration" />
      </div>
    </div>
  );
}

export default HomePage;
