// Pilot.js
import React, { useState } from 'react';
import './Pilot.css';

function Pilot() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [message, setMessage] = useState('');

  const correctUsername = 'sam';
  const correctPassword = 'f7d3k9n2';

  const handleLogin = () => {
    if (username === correctUsername && password === correctPassword) {
      setLoggedIn(true);
    } else {
      alert('Incorrect username or password.');
    }
  };

  const handleCreateEmails = () => {
    // Regular expression to match the required YouTube channel URL format
    const youtubeUrlPattern = /^(https:\/\/)?(www\.)?youtube\.com\/@[\w-]+$/;

    if (youtubeUrlPattern.test(youtubeUrl)) {
      // Use a relative URL for the fetch call
      fetch('/api/pilot-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ youtubeUrl, username }), // Send YouTube URL and username to backend
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorData => {
              throw new Error(errorData.error || 'Network response was not ok');
            });
          }
          return response.json();
        })
        .then(data => {
          setMessage('The emails will be sent to your inbox once they are ready.');
          setYoutubeUrl(''); // Clear form
        })
        .catch(error => {
          console.error('Error:', error);
          alert(error.message || 'There was an error submitting your request. Please try again.');
        });
    } else {
      alert('Please enter a valid YouTube URL in the format: https://www.youtube.com/@{channelName} or www.youtube.com/@{channelName}');
    }
  };

  return (
    <div className="container">
      <h1>Rubybeam</h1>
      {!loggedIn ? (
        <>
          <h3>Username</h3>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input-large"
          />
          <h3>Password</h3>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-large"
          />
          <button className="login-button" onClick={handleLogin}>Login</button>
        </>
      ) : (
        <>
          <h3>YouTube Channel URL</h3>
          <input
            type="url"
            value={youtubeUrl}
            onChange={(e) => setYoutubeUrl(e.target.value)}
            className="input-large"
          />
          <button className="create-emails-button" onClick={handleCreateEmails}>Create Emails</button>
          {message && <p>{message}</p>}
        </>
      )}
    </div>
  );
}

export default Pilot;
